import React from "react";

const ChallengerDisplayer = () => {
  return (
    <iframe
      src="https://gamma.app/embed/kmibdqb16d0tinr"
      style={{
        width: "100vw",
        maxWidth: "100%",
        height: "100vh",
        position: "absolute",
        border: "none",
      }}
      allow="fullscreen"
      title="Grand Challenge 3 Points 🏀 Basket AR CPDE by FANarZONE"
    ></iframe>
  );
};

export default ChallengerDisplayer;
