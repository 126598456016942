import React, { useState, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom/dist";
import { styled } from "@mui/styles";
import AppIcon from "../../assets/appIcon.jpg";

const PREFIX = "AppBanner";

const classes = {
  root: `${PREFIX}-root`,
  isHidden: `${PREFIX}-isHidden`,
  leftContainer: `${PREFIX}-leftContainer`,
  closeIcon: `${PREFIX}-closeIcon`,
  contentContainer: `${PREFIX}-contentContainer`,
  imgStyle: `${PREFIX}-imgStyle`,
  appNameContainer: `${PREFIX}-appNameContainer`,
  messageButtonStyle: `${PREFIX}-messageButtonStyle`,
};

const AppBannerContainer = styled("div")(({ theme }) => {
  return {
    [`& .${classes.root}`]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      cursor: "pointer",
      backgroundColor: "rgb(42,42,42)",
      borderBottom: "1px solid #343434",
      padding: "0.5rem",
      zIndex: 1050,
      position: "fixed",
      bottom: "0",
      height: "80px",
    },
    [`& .${classes.isHidden}`]: {
      display: "none",
      height: 0,
    },
    [`& .${classes.leftContainer}`]: {
      display: "flex",
      alignItems: "center",
    },
    [`& .${classes.closeIcon}`]: {
      cursor: "pointer",
      marginRight: "0.25rem",
      width: "20px",
    },
    [`& .${classes.contentContainer}`]: {
      display: "flex",
      color: "rgba(255, 255, 255, 0.7)",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      maxWidth: "950px",
    },
    [`& .${classes.imgStyle}`]: {
      width: "57px",
      objectFit: "contain",
      marginRight: "0.5rem",
      borderRadius: "20%",
    },
    [`& .${classes.appNameContainer}`]: {
      display: "flex",
      flexDirection: "column",
      height: "40px",
    },
    [`& .${classes.messageButtonStyle}`]: {
      display: "flex",
      flexDirection: "column",
      alignContent: "space-between",
      height: "100%",
    },
  };
});

const AppBanner = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [storeMessage, setStoreMessage] = useState("");
  const history = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (/iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent)) {
      setStoreMessage(t("landing.appBannerAppAppleStoreName"));
    } else {
      setStoreMessage(t("landing.appBannerAppGoogleStoreName"));
    }
  }, [t]);

  if (location.pathname.includes("challengecreps")) {
    return null;
  } else
    return (
      <AppBannerContainer>
        <div
          className={isOpen ? classes.root : classes.isHidden}
          style={{
            // top: props.isResponsive && props.isScrollTriggered ? 0 : props.isResponsive ? '54px' : '65px',
            WebkitTransition: "all .3s ease",
            MozTransition: "all .3s ease",
            OTransition: "all .3s ease",
            transition: "all .3s ease",
          }}
        >
          <div
            className={classes.contentContainer}
            onClick={() => history("/account")}
          >
            <div className={classes.leftContainer}>
              <ClearIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
                className={classes.closeIcon}
              />
              <img src={AppIcon} className={classes.imgStyle} />
              <div className={classes.appNameContainer}>
                <Typography variant="body1">
                  {t("landing.appBannerAppName")}
                </Typography>
                <Typography variant="caption">
                  {t("landing.appBannerAppPrice")}
                  {storeMessage}
                </Typography>
              </div>
            </div>
            <div className={classes.messageButtonStyle}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  if (storeMessage.includes("Apple"))
                    window.location.assign(
                      "https://apps.apple.com/us/app/fanarzone-by-artdesignstory/id1559258124"
                    );
                  else
                    window.location.assign(
                      "https://play.google.com/store/apps/details?id=fr.artdesignstory.exparience.digitmuz_app"
                    );
                }}
              >
                {t("landing.bannerMessageButton")}
              </Button>
            </div>
          </div>
        </div>
      </AppBannerContainer>
    );
};

export default AppBanner;
