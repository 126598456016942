import React from "react";
import "./footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Footer = () => {
  if (location.pathname.includes("challengecreps")) {
    return null;
  } else
    return (
      <div className="footer-container">
        <div className="footer-keep-in-touch">KEEP IN TOUCH</div>
        <div className="footer-icons-container">
          <a
            href="https://www.instagram.com/artdesignstory_paris/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className="footer-icons" />
          </a>
          <a
            href="https://twitter.com/artdesignpaint2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon className="footer-icons" />
          </a>
          <a
            href="https://www.facebook.com/ArtDesignPaintingParis/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className="footer-icons" />
          </a>
          <a
            href="mailto:hello@artdesignstory.fr?subject=Contact FANartZONE"
            target="_self"
          >
            <MailOutlineIcon className="footer-icons" />
          </a>
        </div>
        <div className="footer-company-text">© ArtDesignStory PARIS</div>
        <div className="footer-company-location">
          78 avenue des Champs-Elysées 75008 PARIS
        </div>
        <div className="footer-company-mail">hello@artdesignstory.fr</div>
      </div>
    );
};

export default Footer;
